import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { LoadingSuccessStory } from "@/types/page";

import { getLocalStorage, setLocalStorage } from "./localStorage";

import { getAllBreeds } from "../API";
import { Breed, FunnelData, Fields } from "../types";

type FunnelStorage = FunnelData[];

type FunnelStore = {
	data: FunnelStorage;
	setProperty: (index: number, data: FunnelData) => void;
	setPropertyWithId: (id: string, data: Partial<FunnelData>) => void;
	removePet: (id: string) => void;
	initialisePet: (name: string, defaultData?: Partial<FunnelData>) => void;

	setFirstName: (name: string) => void;
	setEmail: (email: string) => void;
	setMarketingOptIn: (optIn: boolean) => void;

	iteration: number;
	setIteration: (iteration: number) => void;

	breeds: Breed[];
	fetchBreeds: () => void;
	[Fields.FIRSTNAME]?: string;
	[Fields.EMAIL]?: string;
	[Fields.MARKETINGOPTIN]?: boolean;

	successStory: LoadingSuccessStory | null;
	setSuccessStory: (successStory: LoadingSuccessStory) => void;

	resetFunnelData: (dataOverride?: FunnelStorage) => void;

	APIError: string;
	setAPIError: (newError: string) => void;

	setFunnelDataFromLocalStorage: () =>
		| FunnelStorage
		| {
				[Fields.FIRSTNAME]?: string;
				[Fields.EMAIL]?: string;
				[Fields.MARKETINGOPTIN]?: boolean;
		  };
};

const FUNNEL_STORAGE_KEY = "funnelStorage_data";
export const FUNNEL_STORAGE_FIRST = "funnelStorage_first";
export const FUNNEL_STORAGE_EMAIL = "funnelStorage_email";
export const FUNNEL_STORAGE_OPT_IN = "funnelStorage_opt_in";

const getLocalStorageData = () => {
	if (typeof window === "undefined") {
		return [];
	}

	return (getLocalStorage(FUNNEL_STORAGE_KEY) || []) as FunnelStorage;
};

const resetLocalStorage = () => {
	setLocalStorage(FUNNEL_STORAGE_KEY, []);
};

const setLocalStorageItem = (data: FunnelStorage) => {
	setLocalStorage(FUNNEL_STORAGE_KEY, data);
};

const removePetLocalStorage = (id: string) => {
	const currentData = getLocalStorageData();

	const index = currentData.findIndex(({ petId }) => id === petId);

	if (index === -1) {
		return;
	}

	currentData.splice(index, 1);

	setLocalStorage(FUNNEL_STORAGE_KEY, currentData);
};

const initialPetData: FunnelData = {
	[Fields.PETID]: "0",
	[Fields.NAME]: "",
	[Fields.ACTIVITY]: "30-60 mins",
	[Fields.ADULTAGE]: undefined,
	[Fields.ADULTWEIGHT]: undefined,
	[Fields.HEALTHISSUES]: undefined,
	[Fields.ALLERGIES]: undefined,
	[Fields.BREED]: undefined,
	[Fields.DIET]: [],
	[Fields.ESTIMATEDWEIGHT]: undefined,
	[Fields.FUSSY]: "isn't fussy",
	[Fields.GENDER]: undefined,
	[Fields.GOAL]: undefined,
	[Fields.LIFESTAGE]: undefined,
	[Fields.MONTHS]: undefined,
	[Fields.NEUTERED]: undefined,
	[Fields.OLDPUPPYAGE]: undefined,
	[Fields.SHAPE]: "just right",
	[Fields.TRANSFORMEDAGE]: undefined,
	[Fields.TREATS]: "a couple of",
	[Fields.WEIGHT]: undefined,
	[Fields.YEARS]: undefined,
	[Fields.IMPROVEHEALTH]: undefined,
	[Fields.COAT]: undefined,
	[Fields.MOBILITY]: undefined,
	[Fields.DIGESTION]: undefined,
	[Fields.STOOLS]: "firm and moist",
	[Fields.ORAL]: undefined,
	[Fields.BEHAVIOURAL]: undefined,
	[Fields.WORKINGDOG]: false,
	[Fields.BREEDCOUNT]: 0,
	[Fields.PRONOUNS]: undefined,
};

const makeIntialDataTypeSafe = (funnelData: FunnelData) => {
	return {
		...funnelData,
		[Fields.DIET]: funnelData[Fields.DIET] || [],
		[Fields.WORKINGDOG]: funnelData[Fields.WORKINGDOG] === true ? true : false,
	};
};

export const initialFunnelStorageState = (): FunnelStorage => {
	const funnelData = getLocalStorageData();

	if (!funnelData) {
		return [];
	}

	const dataWithDefaults = funnelData.map((data) =>
		makeIntialDataTypeSafe(data)
	);

	return dataWithDefaults;
};

export const initialFunnelState: {
	data: FunnelData[];
	APIError: string;
	[Fields.BREEDS]: Breed[];
	[Fields.FIRSTNAME]?: string;
	[Fields.EMAIL]?: string;
	[Fields.MARKETINGOPTIN]?: boolean;
} = {
	data: initialFunnelStorageState() || [],
	APIError: "",
	[Fields.BREEDS]: [],
	[Fields.FIRSTNAME]: getLocalStorage(FUNNEL_STORAGE_FIRST) || undefined,
	[Fields.EMAIL]: getLocalStorage(FUNNEL_STORAGE_EMAIL) || undefined,
	[Fields.MARKETINGOPTIN]: getLocalStorage(FUNNEL_STORAGE_OPT_IN) || true,
};

const Store: (
	set: (...args: any[]) => void,
	get: () => FunnelStore
) => FunnelStore = (set, get) => ({
	...initialFunnelState,

	iteration: 1,
	setIteration: (iteration) =>
		set({
			iteration,
		}),

	setFunnelDataFromLocalStorage: () => {
		const data = initialFunnelStorageState();
		const first = getLocalStorage(FUNNEL_STORAGE_FIRST);
		const email = getLocalStorage(FUNNEL_STORAGE_EMAIL);
		const optIn = getLocalStorage(FUNNEL_STORAGE_OPT_IN);

		set(() => ({
			data,
			[Fields.FIRSTNAME]: first,
			[Fields.EMAIL]: email,
			[Fields.MARKETINGOPTIN]: optIn,
		}));

		return {
			data,
			email,
			firstName: first,
			marketingOptIn: optIn,
		};
	},

	initialisePet: (name, defaultData = {}) => {
		const petId = defaultData[Fields.PETID]
			? defaultData[Fields.PETID]
			: (performance.now().toString(36) + Math.random().toString(36)).replace(
					/\./g,
					""
				);

		const initialData: FunnelData = {
			...initialPetData,
			...defaultData,
			name,
			petId,
			createdAt: new Date(),
		};

		const currentState = get().data;

		setLocalStorageItem([...currentState, initialData]);

		set(() => ({ data: [...currentState, initialData] }));
	},

	setPropertyWithId: (id, data) => {
		const currentState = get().data;

		const currentDogStateIndex = currentState.findIndex(
			({ petId }) => petId === id
		);

		const newDogData = {
			...(currentState[currentDogStateIndex] || {}),
			...data,
		};

		const newState = [...currentState];

		if (currentDogStateIndex > -1) {
			newState[currentDogStateIndex] = newDogData;
		}

		setLocalStorageItem(newState);

		set(() => ({ data: newState }));
	},

	setFirstName: (name) => {
		set({ [Fields.FIRSTNAME]: name });
		setLocalStorage(FUNNEL_STORAGE_FIRST, name);
	},

	setEmail: (email) => {
		set({ [Fields.EMAIL]: email });
		setLocalStorage(FUNNEL_STORAGE_EMAIL, email);
	},

	setMarketingOptIn: (optIn) => {
		set({ [Fields.MARKETINGOPTIN]: optIn });
		setLocalStorage(FUNNEL_STORAGE_OPT_IN, optIn);
	},

	setProperty: (index, data) => {
		const currentState = get().data;

		const newDogData = {
			...(currentState[index] || {}),
			...data,
		};

		const newState = [...currentState];

		newState[index] = newDogData;

		set(() => ({ data: newState }));
	},

	removePet: (id) => {
		removePetLocalStorage(id);
		const currentState = get().data;
		const dogIndex = currentState.findIndex(({ petId }) => petId === id);

		const newState = [...currentState];
		newState.splice(dogIndex, 1);

		set(() => ({ data: newState }));
	},

	setSuccessStory: (newSuccessStory) => {
		set({ successStory: newSuccessStory });
	},
	successStory: null,

	resetFunnelData: (dataOverride) => {
		resetLocalStorage();
		set({
			data: dataOverride || [],
			successStory: null,
		});
	},

	getAllFunnelDataForDog: (id: string) => {
		const { data } = get();

		const dogData = data.find(({ name }) => name === id);

		return dogData;
	},

	fetchBreeds: async () => {
		const allBreedsData = await getAllBreeds();
		set({ breeds: await allBreedsData });
	},

	setAPIError: (newError) =>
		set({
			APIError: newError,
		}),
});

export const useFunnelStore = create(devtools(Store));
